import React, { useContext, useEffect, useState } from 'react';
import { PageHeader, Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { PaginationConfig } from 'antd/lib/pagination';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { StoreContext } from '../../store';
import { TrialModel } from '../../../api';
import { trialApi } from '../../apis';
import { AlertTypes, TRIAL_STATUS_NAMES } from '../../constants';

const DATE_FORMAT = 'DD.MM.YYYY в HH:mm';

const TrialList: React.FC = () => {
  const { SetAlert } = useContext(StoreContext).actions;
  const [loading, setLoading] = useState(false);
  const [trials, setTrials] = useState([] as TrialModel[]);
  const [total, setTotal] = useState(0);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 25,
  });

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await trialApi.searchTrials(undefined, (pagination.current || 1) - 1, pagination.pageSize);
      setTrials(response.data.items || []);
      setTotal(response.data.totalItemCount || 0);
    } catch (e) {
      SetAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка триалов',
        description: e.toString(),
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, [pagination]);

  const onTableChange = (tablePagination: PaginationConfig) => setPagination(tablePagination as TablePaginationConfig);

  const columns = [
    {
      title: 'ИД',
      dataIndex: 'id',
      ellipsis: false,
    },
    {
      title: 'Тенант',
      dataIndex: 'tenantId',
      ellipsis: false,
      render: (_text: unknown, trial: TrialModel) => (
        <Link
          to={{
            pathname: `/tenants/${trial.tenantId}`,
          }}
        >
          {trial.tenantId}
        </Link>
      ),
    },
    {
      title: 'Пользователь',
      dataIndex: 'userId',
      ellipsis: false,
      render: (_text: unknown, trial: TrialModel) => (
        <Link
          to={{
            pathname: `/users/${trial.userId}`,
          }}
        >
          {trial.userId}
        </Link>
      ),
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdOn',
      render: (_text: unknown, trial: TrialModel) => trial.createdOn && moment(trial.createdOn).format(DATE_FORMAT),
      ellipsis: false,
    },
    {
      title: 'Полное ФИО',
      dataIndex: 'fullName',
      ellipsis: true,
    },
    {
      title: 'Электронная почта',
      dataIndex: 'email',
      ellipsis: true,
    },
    {
      title: 'Номер телефона',
      dataIndex: 'phoneNumber',
      ellipsis: true,
    },
    {
      title: 'Компания',
      dataIndex: 'company.name',
      ellipsis: true,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (_text: unknown, trial: TrialModel) => TRIAL_STATUS_NAMES[trial.status],
      ellipsis: false,
    },
    {
      title: 'Дата истечения',
      dataIndex: 'expiresOn',
      render: (_text: unknown, trial: TrialModel) => trial.expiresOn && moment(trial.expiresOn).format(DATE_FORMAT),
      ellipsis: false,
    },
  ];

  return (
    <div>
      <PageHeader title="Триалы" />
      <Table
        columns={columns}
        dataSource={trials.map((item, i) => ({ key: i, ...item }))}
        loading={loading}
        pagination={{ ...pagination, total }}
        onChange={onTableChange}
      />
    </div>
  );
};

export default TrialList;
