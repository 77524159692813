import React, { useContext, useState } from 'react';
import { Button, Form, Input, PageHeader, Space } from 'antd';
import { useHistory } from 'react-router';
import { RollbackOutlined, SaveOutlined } from '@ant-design/icons';

import { TenantCompanyModel, TenantCreationRequest, TenantExternalInfoModel, TenantTariffModel } from '../../../api';
import { StoreContext } from '../../store';
import { tenantApi } from '../../apis';
import { AlertTypes } from '../../constants';
import ExternalInfoListEdit from '../../components/tenants/ExternalInfoListEdit';

const TenantAdd: React.FC = () => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const { SetAlert } = useContext(StoreContext).actions;
  const history = useHistory();
  const [externals, setExternals] = useState([] as TenantExternalInfoModel[]);

  const onExternalsChanged = (items: TenantExternalInfoModel[]) => {
    setExternals(items);
  };

  const onFinish = async () => {
    const company: TenantCompanyModel = {
      name: form.getFieldValue('companyName') || '',
    };
    const tariff: TenantTariffModel = {
      code: form.getFieldValue('tariffCode'),
    };
    const newTenant: TenantCreationRequest = {
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
      company,
      tariff,
      externals,
    };

    setSaving(true);
    try {
      const response = await tenantApi.createTenant(newTenant);
      SetAlert({
        type: AlertTypes.SUCCESS,
        message: 'Добавлен новый тенант',
        description: newTenant.name,
      });
      history.push(`/tenants/${response.data.id}`);
    } catch (e) {
      SetAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при сохранении тенанта',
        description: e.toString(),
      });
      setSaving(false);
    }
  };

  const onBackBtnClick = () => {
    history.push('/tenants');
  };

  return (
    <div>
      <PageHeader title="Новый тенант" />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label="Наименование" name="name" rules={[{ required: true, message: 'Введите наименование' }]}>
          <Input placeholder="Наименование" />
        </Form.Item>
        <Form.Item label="Описание" name="description">
          <Input placeholder="Описание" />
        </Form.Item>
        <Form.Item label="Компания" name="companyName">
          <Input placeholder="Компания" />
        </Form.Item>
        <Form.Item
          label="Тариф"
          name="tariffCode"
          rules={[{ required: true, message: 'Введите тариф (default или unlimited)' }]}
        >
          <Input placeholder="Тариф" />
        </Form.Item>
        <Form.Item>
          <ExternalInfoListEdit externals={externals} onChanged={onExternalsChanged} />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button disabled={saving} htmlType="submit" icon={<SaveOutlined />} type="primary">
              Сохранить
            </Button>
            <Button icon={<RollbackOutlined />} onClick={onBackBtnClick}>
              Отмена
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TenantAdd;
