import React, { useContext, useState, useEffect } from 'react';
import { Button, Form, Input, PageHeader, Space } from 'antd';
import { useParams, useHistory } from 'react-router';
import { RollbackOutlined, SaveOutlined } from '@ant-design/icons';
import { StatusCodes } from 'http-status-codes';

import { StoreContext } from '../../store';
import { UserModel, UserLoginInfoModel, UserUpdatingRequest } from '../../../api';
import { userApi } from '../../apis';
import { AlertTypes, IDENTITY_ERROR_PREFIX } from '../../constants';
import LoginInfoListEdit from '../../components/users/LoginInfoListEdit';

const RoleEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [form] = Form.useForm();
  const { SetAlert } = useContext(StoreContext).actions;
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({} as UserModel);

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await userApi.getUser(id);
      setUser(response.data);
    } catch (e) {
      SetAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных пользователя',
        description: e.toString(),
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, []);

  const loadDataToForm = () => {
    form.setFieldsValue({
      login: user.login,
      email: user.email,
      phoneNumber: user.phoneNumber,
      fullName: user.fullName,
      shortName: user.shortName,
      familyName: user.familyName,
      givenName: user.givenName,
      middleName: user.middleName,
      roleNames: user.roles?.map((r) => r.name).join(', '),
      tenantNames: user.tenantNames,
    });
  };
  useEffect(loadDataToForm, [user]);

  const onLoginsChanged = (items: UserLoginInfoModel[]) => {
    setUser({
      ...user,
      logins: items,
    });
  };

  const onFinish = async () => {
    const newUser: UserUpdatingRequest = {
      login: form.getFieldValue('login'),
      email: form.getFieldValue('email'),
      phoneNumber: form.getFieldValue('phoneNumber'),
      fullName: form.getFieldValue('fullName'),
      shortName: form.getFieldValue('shortName'),
      familyName: form.getFieldValue('familyName'),
      givenName: form.getFieldValue('givenName'),
      middleName: form.getFieldValue('middleName'),
      roleNames: form.getFieldValue('roleNames'),
      tenantNames: form.getFieldValue('tenantNames'),
      logins: user.logins,
    };

    setLoading(true);
    try {
      await userApi.updateUser(id, newUser);
      SetAlert({
        type: AlertTypes.SUCCESS,
        message: 'Пользователь изменен',
        description: newUser.login,
      });
      history.push(`/users/${id}`);
    } catch (e) {
      if (e.response.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        const problem = e.response.data;
        const errors = Object.keys(problem).filter((k) => k.startsWith(IDENTITY_ERROR_PREFIX));

        if (errors.length) {
          form.setFields([
            {
              name: 'login',
              value: newUser.login,
              errors: errors.map((k) => problem[k]),
            },
          ]);
          setLoading(false);
          return;
        }
      }

      SetAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при изменении пользователя',
        description: e.toString(),
      });
      setLoading(false);
    }
  };

  const onBackBtnClick = () => {
    history.push('/users');
  };

  return (
    <div>
      <PageHeader title={`Редактирование пользователя ${user.login}`} />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label="Логин" name="login" rules={[{ required: true, message: 'Введите логин' }]}>
          <Input placeholder="Логин" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Введите Email' },
            { type: 'email', message: 'Некорректный Email' },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item label="Телефон" name="phoneNumber" rules={[{ required: true, message: 'Введите номер телефона' }]}>
          <Input placeholder="Телефон" />
        </Form.Item>
        <Form.Item label="Полное ФИО" name="fullName" rules={[{ required: true, message: 'Введите полное ФИО' }]}>
          <Input placeholder="Полное ФИО" />
        </Form.Item>
        <Form.Item label="Краткое ФИО" name="shortName" rules={[{ required: true, message: 'Введите краткое ФИО' }]}>
          <Input placeholder="Краткое ФИО" />
        </Form.Item>
        <Form.Item label="Фамилия" name="familyName" rules={[{ required: true, message: 'Введите фамилию' }]}>
          <Input placeholder="Фамилия" />
        </Form.Item>
        <Form.Item label="Имя" name="givenName" rules={[{ required: true, message: 'Введите имя' }]}>
          <Input placeholder="Имя" />
        </Form.Item>
        <Form.Item label="Отчество" name="middleName">
          <Input placeholder="Отчество" />
        </Form.Item>
        <Form.Item label="Роли" name="roleNames">
          <Input placeholder="Роли" />
        </Form.Item>
        <Form.Item label="Тенанты" name="tenantNames">
          <Input placeholder="Тенанты" />
        </Form.Item>
        <Form.Item>
          <LoginInfoListEdit logins={user.logins || []} onChanged={onLoginsChanged} />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button disabled={loading} htmlType="submit" icon={<SaveOutlined />} type="primary">
              Сохранить
            </Button>
            <Button icon={<RollbackOutlined />} onClick={onBackBtnClick}>
              Отмена
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RoleEdit;
