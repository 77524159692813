import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ContainerOutlined,
  UserOutlined,
  TeamOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { ClickParam } from 'antd/es/menu';
import React, { createElement, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { AboutModel } from '../../api';
import { applicationApi } from '../apis';
import { AlertTypes } from '../constants';
import { StoreContext } from '../store';
import UserInfo from '../components/UserInfo';
import { Logo, LogoElmaBot } from '../assets';

const { Header, Content, Sider } = Layout;

interface IMenuItem {
  path: string;
  icon: React.ReactNode;
  content: string;
}

const BaseLayout: React.FC = (props) => {
  const { children } = props;
  const history = useHistory();
  const location = useLocation();
  const { SetAlert } = useContext(StoreContext).actions;
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(['']);
  const [appInfo, setAppInfo] = useState({ name: '', description: '', version: '' } as AboutModel);

  const menuItems: IMenuItem[] = [
    {
      path: '/tenants',
      icon: <ContainerOutlined />,
      content: 'Тенанты',
    },
    {
      path: '/roles',
      icon: <TeamOutlined />,
      content: 'Роли',
    },
    {
      path: '/users',
      icon: <UserOutlined />,
      content: 'Пользователи',
    },
    {
      path: '/trials',
      icon: <FieldTimeOutlined />,
      content: 'Триалы',
    },
  ];

  const onMenuClick = (clickParam: ClickParam) => {
    const { key } = clickParam;
    setSelectedKeys([key]);
    history.push(key);
  };

  const initSelectedKeys = () => {
    const { pathname } = window.location;

    setSelectedKeys(menuItems.filter((item) => pathname.indexOf(item.path) === 0).map((item) => item.path));
  };
  useEffect(initSelectedKeys, []);

  const loadAppInfoAsync = async () => {
    try {
      const response = await applicationApi.getAboutInfo();
      setAppInfo(response.data);
    } catch (e) {
      SetAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных о приложении',
        description: e.toString(),
      });
    }
  };
  const loadAppInfo = () => {
    loadAppInfoAsync();
  };
  useEffect(loadAppInfo, []);

  const onLocationChanged = () => {
    setSelectedKeys(menuItems.filter((item) => location.pathname.indexOf(item.path) === 0).map((item) => item.path));
  };

  useEffect(onLocationChanged, [location]);

  return (
    <Layout className="base-layout">
      <Sider collapsible className="base-layout-sider" collapsed={collapsed} theme="dark" trigger={null}>
        <div className="base-layout-logo-container">
          {collapsed ? (
            <Logo className="base-layout-logo-elma-bot" />
          ) : (
            <LogoElmaBot className="base-layout-logo-elma-bot" />
          )}
        </div>
        <Menu mode="inline" selectedKeys={selectedKeys} theme="dark" onClick={onMenuClick}>
          {menuItems.map((menuItem) => (
            <Menu.Item key={menuItem.path} icon={menuItem.icon}>
              {menuItem.content}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout className="base-layout-main">
        <Header className="base-layout-header">
          {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => setCollapsed(!collapsed),
          })}
          <code className="app-info">Version: {appInfo.version}</code>
          <UserInfo />
        </Header>
        <Layout className="base-layout-scrollable">
          <Layout className="base-layout-scrollable-content">
            <Content className="base-layout-content">{children}</Content>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default BaseLayout;
