import { ActionTypes } from '../../constants';
import { isReloading } from '../../utils/oidcUtil';
import { IAlert, IStore } from '../types';

export const SetAlert = async ({ dispatch }: IStore, alert: IAlert): Promise<void> => {
  if (isReloading()) {
    return;
  }

  dispatch({
    type: ActionTypes.SET_ALERT,
    payload: alert,
  });
};

export const RemoveAlert = async ({ dispatch }: IStore, id: string): Promise<void> => {
  dispatch({
    type: ActionTypes.REMOVE_ALERT,
    payload: id,
  });
};
