import React from 'react';
import { Button, Input, Table, Tooltip } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { TenantExternalInfoModel } from '../../../api';

export interface IExternalInfoListEditProps {
  externals: TenantExternalInfoModel[];
  onChanged: (items: TenantExternalInfoModel[]) => void;
}

const ExternalInfoListEdit: React.FC<IExternalInfoListEditProps> = ({ externals, onChanged = () => {} }) => {
  const onProviderIdChanged = (value: string, index: number) => {
    const items = [...externals];
    items[index] = {
      providerId: value,
      tenantId: externals[index].tenantId,
    };
    onChanged(items);
  };

  const onTenantIdChanged = (value: string, index: number) => {
    const items = [...externals];
    items[index] = {
      providerId: externals[index].providerId,
      tenantId: value,
    };
    onChanged(items);
  };

  const onAddItem = () => {
    const items = [...externals];
    items.push({ providerId: '', tenantId: '' });
    onChanged(items);
  };

  const onRemoveItem = (index: number) => {
    const items = [...externals];
    items.splice(index, 1);
    onChanged(items);
  };

  const columns = [
    {
      title: 'ИД внешнего поставщика',
      dataIndex: 'providerId',
      key: 'providerId',
      render: (value: string, _: unknown, index: number) => (
        <Input
          required
          placeholder="Укажите ИД внешнего поставщика"
          value={value}
          onChange={(e) => onProviderIdChanged(e.target.value, index)}
        />
      ),
    },
    {
      title: 'ИД тенанта в поставщике',
      dataIndex: 'tenantId',
      key: 'tenantId',
      render: (value: string, _: unknown, index: number) => (
        <Input
          required
          placeholder="Укажите ИД тенанта в поставщике"
          value={value}
          onChange={(e) => onTenantIdChanged(e.target.value, index)}
        />
      ),
    },
    {
      width: '120px',
      title: () => (
        <Tooltip placement="top" title="Добавить информацию о тенанте из внешнего поставщика">
          <Button icon={<PlusCircleOutlined />} type="ghost" onClick={() => onAddItem()}>
            Добавить
          </Button>
        </Tooltip>
      ),
      render: (_: string, __: unknown, index: number) => (
        <Tooltip placement="top" title="Удалить информацию о тенанте из внешнего поставщика">
          <Button danger icon={<DeleteOutlined />} type="ghost" onClick={() => onRemoveItem(index)}>
            Удалить
          </Button>
        </Tooltip>
      ),
    },
  ];

  return <Table columns={columns} dataSource={externals} pagination={false} />;
};

export default ExternalInfoListEdit;
