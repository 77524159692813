/* tslint:disable */
/* eslint-disable */
/**
 * Администрирование ELMA Bot
 * Управление пользователями, тенантами и т.д.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * Модель информации о приложении.
 * @export
 * @interface AboutModel
 */
export interface AboutModel {
    /**
     * Наименование.
     * @type {string}
     * @memberof AboutModel
     */
    'name'?: string | null;
    /**
     * Описание.
     * @type {string}
     * @memberof AboutModel
     */
    'description'?: string | null;
    /**
     * Конечная точка.
     * @type {string}
     * @memberof AboutModel
     */
    'endpoint'?: string | null;
    /**
     * Версия.
     * @type {string}
     * @memberof AboutModel
     */
    'version'?: string | null;
}
/**
 * Модель информации о работоспособности.
 * @export
 * @interface HealthModel
 */
export interface HealthModel {
    /**
     * Статус работоспособности.
     * @type {string}
     * @memberof HealthModel
     */
    'status'?: string | null;
}
/**
 *
 * @export
 * @interface PasswordChangingRequest
 */
export interface PasswordChangingRequest {
    /**
     *
     * @type {string}
     * @memberof PasswordChangingRequest
     */
    'currentPassword': string;
    /**
     *
     * @type {string}
     * @memberof PasswordChangingRequest
     */
    'newPassword': string;
}
/**
 *
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     *
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 *
 * @export
 * @interface RoleCreationRequest
 */
export interface RoleCreationRequest {
    /**
     *
     * @type {string}
     * @memberof RoleCreationRequest
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof RoleCreationRequest
     */
    'description'?: string | null;
}
/**
 *
 * @export
 * @interface RoleCreationResponse
 */
export interface RoleCreationResponse {
    /**
     *
     * @type {string}
     * @memberof RoleCreationResponse
     */
    'id': string;
}
/**
 *
 * @export
 * @interface RoleModel
 */
export interface RoleModel {
    /**
     *
     * @type {string}
     * @memberof RoleModel
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof RoleModel
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof RoleModel
     */
    'description'?: string | null;
}
/**
 *
 * @export
 * @interface RoleModelPaginationResponse
 */
export interface RoleModelPaginationResponse {
    /**
     *
     * @type {Array<RoleModel>}
     * @memberof RoleModelPaginationResponse
     */
    'items'?: Array<RoleModel> | null;
    /**
     *
     * @type {boolean}
     * @memberof RoleModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     *
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     *
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     *
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     *
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     *
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     *
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 *
 * @export
 * @interface RoleUpdatingRequest
 */
export interface RoleUpdatingRequest {
    /**
     *
     * @type {string}
     * @memberof RoleUpdatingRequest
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof RoleUpdatingRequest
     */
    'description'?: string | null;
}
/**
 *
 * @export
 * @interface TenantCompanyModel
 */
export interface TenantCompanyModel {
    /**
     *
     * @type {string}
     * @memberof TenantCompanyModel
     */
    'name': string;
}
/**
 *
 * @export
 * @interface TenantCreationRequest
 */
export interface TenantCreationRequest {
    /**
     *
     * @type {string}
     * @memberof TenantCreationRequest
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof TenantCreationRequest
     */
    'description'?: string | null;
    /**
     *
     * @type {TenantCompanyModel}
     * @memberof TenantCreationRequest
     */
    'company': TenantCompanyModel;
    /**
     *
     * @type {TenantTariffModel}
     * @memberof TenantCreationRequest
     */
    'tariff': TenantTariffModel;
    /**
     *
     * @type {Array<TenantExternalInfoModel>}
     * @memberof TenantCreationRequest
     */
    'externals': Array<TenantExternalInfoModel>;
}
/**
 *
 * @export
 * @interface TenantCreationResponse
 */
export interface TenantCreationResponse {
    /**
     *
     * @type {string}
     * @memberof TenantCreationResponse
     */
    'id': string;
}
/**
 *
 * @export
 * @interface TenantExternalInfoModel
 */
export interface TenantExternalInfoModel {
    /**
     *
     * @type {string}
     * @memberof TenantExternalInfoModel
     */
    'providerId': string;
    /**
     *
     * @type {string}
     * @memberof TenantExternalInfoModel
     */
    'tenantId': string;
}
/**
 *
 * @export
 * @interface TenantModel
 */
export interface TenantModel {
    /**
     *
     * @type {string}
     * @memberof TenantModel
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof TenantModel
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof TenantModel
     */
    'description'?: string | null;
    /**
     *
     * @type {TenantCompanyModel}
     * @memberof TenantModel
     */
    'company': TenantCompanyModel;
    /**
     *
     * @type {TenantTariffModel}
     * @memberof TenantModel
     */
    'tariff': TenantTariffModel;
    /**
     *
     * @type {Array<TenantExternalInfoModel>}
     * @memberof TenantModel
     */
    'externals': Array<TenantExternalInfoModel>;
}
/**
 *
 * @export
 * @interface TenantModelPaginationResponse
 */
export interface TenantModelPaginationResponse {
    /**
     *
     * @type {Array<TenantModel>}
     * @memberof TenantModelPaginationResponse
     */
    'items'?: Array<TenantModel> | null;
    /**
     *
     * @type {boolean}
     * @memberof TenantModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     *
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     *
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     *
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     *
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     *
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     *
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 *
 * @export
 * @interface TenantTariffModel
 */
export interface TenantTariffModel {
    /**
     *
     * @type {string}
     * @memberof TenantTariffModel
     */
    'code': string;
}
/**
 *
 * @export
 * @interface TenantUpdatingRequest
 */
export interface TenantUpdatingRequest {
    /**
     *
     * @type {string}
     * @memberof TenantUpdatingRequest
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof TenantUpdatingRequest
     */
    'description'?: string | null;
    /**
     *
     * @type {TenantCompanyModel}
     * @memberof TenantUpdatingRequest
     */
    'company': TenantCompanyModel;
    /**
     *
     * @type {TenantTariffModel}
     * @memberof TenantUpdatingRequest
     */
    'tariff': TenantTariffModel;
    /**
     *
     * @type {Array<TenantExternalInfoModel>}
     * @memberof TenantUpdatingRequest
     */
    'externals': Array<TenantExternalInfoModel>;
}
/**
 *
 * @export
 * @interface TrialModel
 */
export interface TrialModel {
    /**
     *
     * @type {string}
     * @memberof TrialModel
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof TrialModel
     */
    'tenantId'?: string | null;
    /**
     *
     * @type {string}
     * @memberof TrialModel
     */
    'userId'?: string | null;
    /**
     *
     * @type {string}
     * @memberof TrialModel
     */
    'fullName': string;
    /**
     *
     * @type {string}
     * @memberof TrialModel
     */
    'email': string;
    /**
     *
     * @type {string}
     * @memberof TrialModel
     */
    'companyName': string;
    /**
     *
     * @type {string}
     * @memberof TrialModel
     */
    'phoneNumber': string;
    /**
     *
     * @type {string}
     * @memberof TrialModel
     */
    'expiresOn'?: string | null;
    /**
     *
     * @type {string}
     * @memberof TrialModel
     */
    'createdOn': string;
    /**
     *
     * @type {TrialStatusModel}
     * @memberof TrialModel
     */
    'status': TrialStatusModel;
}


/**
 *
 * @export
 * @interface TrialModelPaginationResponse
 */
export interface TrialModelPaginationResponse {
    /**
     *
     * @type {Array<TrialModel>}
     * @memberof TrialModelPaginationResponse
     */
    'items'?: Array<TrialModel> | null;
    /**
     *
     * @type {boolean}
     * @memberof TrialModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     *
     * @type {number}
     * @memberof TrialModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     *
     * @type {number}
     * @memberof TrialModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     *
     * @type {number}
     * @memberof TrialModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     *
     * @type {number}
     * @memberof TrialModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     *
     * @type {number}
     * @memberof TrialModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     *
     * @type {number}
     * @memberof TrialModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TrialStatusModel = {
    Requested: 'Requested',
    Active: 'Active',
    Expired: 'Expired',
    Cancelled: 'Cancelled'
} as const;

export type TrialStatusModel = typeof TrialStatusModel[keyof typeof TrialStatusModel];


/**
 *
 * @export
 * @interface UserCreationRequest
 */
export interface UserCreationRequest {
    /**
     *
     * @type {string}
     * @memberof UserCreationRequest
     */
    'login': string;
    /**
     *
     * @type {string}
     * @memberof UserCreationRequest
     */
    'password': string;
    /**
     *
     * @type {string}
     * @memberof UserCreationRequest
     */
    'email': string;
    /**
     *
     * @type {string}
     * @memberof UserCreationRequest
     */
    'phoneNumber': string;
    /**
     *
     * @type {string}
     * @memberof UserCreationRequest
     */
    'fullName': string;
    /**
     *
     * @type {string}
     * @memberof UserCreationRequest
     */
    'shortName': string;
    /**
     *
     * @type {string}
     * @memberof UserCreationRequest
     */
    'familyName': string;
    /**
     *
     * @type {string}
     * @memberof UserCreationRequest
     */
    'givenName': string;
    /**
     *
     * @type {string}
     * @memberof UserCreationRequest
     */
    'middleName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserCreationRequest
     */
    'tenantNames'?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserCreationRequest
     */
    'roleNames'?: string | null;
    /**
     *
     * @type {Array<UserLoginInfoModel>}
     * @memberof UserCreationRequest
     */
    'logins'?: Array<UserLoginInfoModel> | null;
}
/**
 * Результат отправки приглашения.
 * @export
 * @interface UserInvitationResult
 */
export interface UserInvitationResult {
    /**
     *
     * @type {boolean}
     * @memberof UserInvitationResult
     */
    'success'?: boolean;
    /**
     *
     * @type {string}
     * @memberof UserInvitationResult
     */
    'errorMessage'?: string | null;
}
/**
 *
 * @export
 * @interface UserLoginInfoModel
 */
export interface UserLoginInfoModel {
    /**
     *
     * @type {string}
     * @memberof UserLoginInfoModel
     */
    'loginProvider': string;
    /**
     *
     * @type {string}
     * @memberof UserLoginInfoModel
     */
    'providerKey': string;
    /**
     *
     * @type {string}
     * @memberof UserLoginInfoModel
     */
    'providerDisplayName'?: string | null;
}
/**
 *
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     *
     * @type {string}
     * @memberof UserModel
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof UserModel
     */
    'login': string;
    /**
     *
     * @type {string}
     * @memberof UserModel
     */
    'password': string;
    /**
     *
     * @type {string}
     * @memberof UserModel
     */
    'email': string;
    /**
     *
     * @type {string}
     * @memberof UserModel
     */
    'phoneNumber': string;
    /**
     *
     * @type {string}
     * @memberof UserModel
     */
    'fullName': string;
    /**
     *
     * @type {string}
     * @memberof UserModel
     */
    'shortName': string;
    /**
     *
     * @type {string}
     * @memberof UserModel
     */
    'familyName': string;
    /**
     *
     * @type {string}
     * @memberof UserModel
     */
    'givenName': string;
    /**
     *
     * @type {string}
     * @memberof UserModel
     */
    'middleName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserModel
     */
    'tenantNames': string;
    /**
     *
     * @type {Array<RoleModel>}
     * @memberof UserModel
     */
    'roles': Array<RoleModel>;
    /**
     *
     * @type {Array<UserLoginInfoModel>}
     * @memberof UserModel
     */
    'logins': Array<UserLoginInfoModel>;
    /**
     *
     * @type {string}
     * @memberof UserModel
     */
    'createdOn': string;
    /**
     *
     * @type {UserStatus}
     * @memberof UserModel
     */
    'status': UserStatus;
    /**
     *
     * @type {UserInvitationResult}
     * @memberof UserModel
     */
    'invitationResult'?: UserInvitationResult;
}


/**
 *
 * @export
 * @interface UserModelPaginationResponse
 */
export interface UserModelPaginationResponse {
    /**
     *
     * @type {Array<UserModel>}
     * @memberof UserModelPaginationResponse
     */
    'items'?: Array<UserModel> | null;
    /**
     *
     * @type {boolean}
     * @memberof UserModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     *
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     *
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     *
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     *
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     *
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     *
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const UserStatus = {
    Invited: 'Invited',
    Active: 'Active',
    Blocked: 'Blocked'
} as const;

export type UserStatus = typeof UserStatus[keyof typeof UserStatus];


/**
 *
 * @export
 * @interface UserUpdatingRequest
 */
export interface UserUpdatingRequest {
    /**
     *
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'login': string;
    /**
     *
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'email': string;
    /**
     *
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'phoneNumber': string;
    /**
     *
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'fullName': string;
    /**
     *
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'shortName': string;
    /**
     *
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'familyName': string;
    /**
     *
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'givenName': string;
    /**
     *
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'middleName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'tenantNames'?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'roleNames'?: string | null;
    /**
     *
     * @type {Array<UserLoginInfoModel>}
     * @memberof UserUpdatingRequest
     */
    'logins'?: Array<UserLoginInfoModel> | null;
}
/**
 *
 * @export
 * @interface ValidationProblemDetails
 */
export interface ValidationProblemDetails {
    [key: string]: any;

    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ValidationProblemDetails
     */
    'errors'?: { [key: string]: Array<string>; } | null;
    /**
     *
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'type'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'title'?: string | null;
    /**
     *
     * @type {number}
     * @memberof ValidationProblemDetails
     */
    'status'?: number | null;
    /**
     *
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'detail'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'instance'?: string | null;
}

/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAboutInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/application/about`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/application/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAboutInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AboutModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAboutInfo(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ApplicationApi.getAboutInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealthInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealthInfo(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ApplicationApi.getHealthInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationApiFp(configuration)
    return {
        /**
         *
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAboutInfo(options?: any): AxiosPromise<AboutModel> {
            return localVarFp.getAboutInfo(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthInfo(options?: any): AxiosPromise<HealthModel> {
            return localVarFp.getHealthInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
    /**
     *
     * @summary Получить информацию о приложении.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getAboutInfo(options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).getAboutInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Получить информацию о работоспособности.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getHealthInfo(options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).getHealthInfo(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
export const ConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConfig: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/configuration/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationApi - functional programming interface
 * @export
 */
export const ConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientConfig(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientConfig(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConfigurationApi.getClientConfig']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ConfigurationApi - factory interface
 * @export
 */
export const ConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationApiFp(configuration)
    return {
        /**
         *
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConfig(options?: any): AxiosPromise<void> {
            return localVarFp.getClientConfig(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
export class ConfigurationApi extends BaseAPI {
    /**
     *
     * @summary Получить конфигурацию клиента.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getClientConfig(options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).getClientConfig(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {RoleCreationRequest} [roleCreationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole: async (roleCreationRequest?: RoleCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/membership/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRole', 'id', id)
            const localVarPath = `/api/v1/auth/membership/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRole', 'id', id)
            const localVarPath = `/api/v1/auth/membership/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {object} [filtration]
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRoles: async (filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/membership/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filtration !== undefined) {
                for (const [key, value] of Object.entries(filtration)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {RoleUpdatingRequest} [roleUpdatingRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole: async (id: string, roleUpdatingRequest?: RoleUpdatingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRole', 'id', id)
            const localVarPath = `/api/v1/auth/membership/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {RoleCreationRequest} [roleCreationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRole(roleCreationRequest?: RoleCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRole(roleCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoleApi.createRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRole(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRole(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoleApi.deleteRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRole(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRole(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoleApi.getRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {object} [filtration]
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchRoles(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchRoles(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoleApi.searchRoles']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {RoleUpdatingRequest} [roleUpdatingRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRole(id: string, roleUpdatingRequest?: RoleUpdatingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRole(id, roleUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoleApi.updateRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleApiFp(configuration)
    return {
        /**
         *
         * @param {RoleCreationRequest} [roleCreationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole(roleCreationRequest?: RoleCreationRequest, options?: any): AxiosPromise<RoleCreationResponse> {
            return localVarFp.createRole(roleCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRole(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole(id: string, options?: any): AxiosPromise<RoleModel> {
            return localVarFp.getRole(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {object} [filtration]
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRoles(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<RoleModelPaginationResponse> {
            return localVarFp.searchRoles(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {RoleUpdatingRequest} [roleUpdatingRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole(id: string, roleUpdatingRequest?: RoleUpdatingRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateRole(id, roleUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI {
    /**
     *
     * @param {RoleCreationRequest} [roleCreationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public createRole(roleCreationRequest?: RoleCreationRequest, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).createRole(roleCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public deleteRole(id: string, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).deleteRole(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public getRole(id: string, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).getRole(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {object} [filtration]
     * @param {number} [pageIndex]
     * @param {number} [pageSize]
     * @param {boolean} [withTotal]
     * @param {number} [fromItemInclusive]
     * @param {number} [toItemExclusive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public searchRoles(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).searchRoles(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {RoleUpdatingRequest} [roleUpdatingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public updateRole(id: string, roleUpdatingRequest?: RoleUpdatingRequest, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).updateRole(id, roleUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {TenantCreationRequest} [tenantCreationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant: async (tenantCreationRequest?: TenantCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/general/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenant: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTenant', 'id', id)
            const localVarPath = `/api/v1/auth/general/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenant: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTenant', 'id', id)
            const localVarPath = `/api/v1/auth/general/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {object} [filtration]
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTenants: async (filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/general/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filtration !== undefined) {
                for (const [key, value] of Object.entries(filtration)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {TenantUpdatingRequest} [tenantUpdatingRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenant: async (id: string, tenantUpdatingRequest?: TenantUpdatingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTenant', 'id', id)
            const localVarPath = `/api/v1/auth/general/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {TenantCreationRequest} [tenantCreationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTenant(tenantCreationRequest?: TenantCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTenant(tenantCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TenantApi.createTenant']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTenant(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTenant(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TenantApi.deleteTenant']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenant(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenant(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TenantApi.getTenant']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {object} [filtration]
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTenants(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTenants(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TenantApi.searchTenants']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {TenantUpdatingRequest} [tenantUpdatingRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTenant(id: string, tenantUpdatingRequest?: TenantUpdatingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTenant(id, tenantUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TenantApi.updateTenant']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantApiFp(configuration)
    return {
        /**
         *
         * @param {TenantCreationRequest} [tenantCreationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant(tenantCreationRequest?: TenantCreationRequest, options?: any): AxiosPromise<TenantCreationResponse> {
            return localVarFp.createTenant(tenantCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenant(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTenant(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenant(id: string, options?: any): AxiosPromise<TenantModel> {
            return localVarFp.getTenant(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {object} [filtration]
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTenants(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<TenantModelPaginationResponse> {
            return localVarFp.searchTenants(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {TenantUpdatingRequest} [tenantUpdatingRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenant(id: string, tenantUpdatingRequest?: TenantUpdatingRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateTenant(id, tenantUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     *
     * @param {TenantCreationRequest} [tenantCreationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public createTenant(tenantCreationRequest?: TenantCreationRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).createTenant(tenantCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public deleteTenant(id: string, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).deleteTenant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getTenant(id: string, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getTenant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {object} [filtration]
     * @param {number} [pageIndex]
     * @param {number} [pageSize]
     * @param {boolean} [withTotal]
     * @param {number} [fromItemInclusive]
     * @param {number} [toItemExclusive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public searchTenants(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).searchTenants(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {TenantUpdatingRequest} [tenantUpdatingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public updateTenant(id: string, tenantUpdatingRequest?: TenantUpdatingRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).updateTenant(id, tenantUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TrialApi - axios parameter creator
 * @export
 */
export const TrialApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {object} [filtration]
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTrials: async (filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/licence/trials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filtration !== undefined) {
                for (const [key, value] of Object.entries(filtration)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrialApi - functional programming interface
 * @export
 */
export const TrialApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrialApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {object} [filtration]
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTrials(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTrials(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TrialApi.searchTrials']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TrialApi - factory interface
 * @export
 */
export const TrialApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrialApiFp(configuration)
    return {
        /**
         *
         * @param {object} [filtration]
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTrials(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<TrialModelPaginationResponse> {
            return localVarFp.searchTrials(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrialApi - object-oriented interface
 * @export
 * @class TrialApi
 * @extends {BaseAPI}
 */
export class TrialApi extends BaseAPI {
    /**
     *
     * @param {object} [filtration]
     * @param {number} [pageIndex]
     * @param {number} [pageSize]
     * @param {boolean} [withTotal]
     * @param {number} [fromItemInclusive]
     * @param {number} [toItemExclusive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialApi
     */
    public searchTrials(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: AxiosRequestConfig) {
        return TrialApiFp(this.configuration).searchTrials(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('blockUser', 'id', id)
            const localVarPath = `/api/v1/auth/membership/users/{id}/block`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {PasswordChangingRequest} [passwordChangingRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPassword: async (id: string, passwordChangingRequest?: PasswordChangingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeUserPassword', 'id', id)
            const localVarPath = `/api/v1/auth/membership/users/{id}/password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChangingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UserCreationRequest} [userCreationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userCreationRequest?: UserCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/membership/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/api/v1/auth/membership/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/api/v1/auth/membership/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Array<string>} [ids]
         * @param {string} [searchText]
         * @param {string} [tenantId]
         * @param {UserStatus} [status]
         * @param {string} [roleName]
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers: async (ids?: Array<string>, searchText?: string, tenantId?: string, status?: UserStatus, roleName?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/membership/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['Ids'] = ids;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['SearchText'] = searchText;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['TenantId'] = tenantId;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (roleName !== undefined) {
                localVarQueryParameter['RoleName'] = roleName;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unlockUser', 'id', id)
            const localVarPath = `/api/v1/auth/membership/users/{id}/unlock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {UserUpdatingRequest} [userUpdatingRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, userUpdatingRequest?: UserUpdatingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            const localVarPath = `/api/v1/auth/membership/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blockUser(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.blockUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {PasswordChangingRequest} [passwordChangingRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserPassword(id: string, passwordChangingRequest?: PasswordChangingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserPassword(id, passwordChangingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.changeUserPassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {UserCreationRequest} [userCreationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userCreationRequest?: UserCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.createUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.deleteUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.getUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {Array<string>} [ids]
         * @param {string} [searchText]
         * @param {string} [tenantId]
         * @param {UserStatus} [status]
         * @param {string} [roleName]
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUsers(ids?: Array<string>, searchText?: string, tenantId?: string, status?: UserStatus, roleName?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchUsers(ids, searchText, tenantId, status, roleName, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.searchUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlockUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlockUser(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.unlockUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {UserUpdatingRequest} [userUpdatingRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, userUpdatingRequest?: UserUpdatingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, userUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.updateUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.blockUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {PasswordChangingRequest} [passwordChangingRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPassword(id: string, passwordChangingRequest?: PasswordChangingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.changeUserPassword(id, passwordChangingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UserCreationRequest} [userCreationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userCreationRequest?: UserCreationRequest, options?: any): AxiosPromise<UserModel> {
            return localVarFp.createUser(userCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: string, options?: any): AxiosPromise<UserModel> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Array<string>} [ids]
         * @param {string} [searchText]
         * @param {string} [tenantId]
         * @param {UserStatus} [status]
         * @param {string} [roleName]
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers(ids?: Array<string>, searchText?: string, tenantId?: string, status?: UserStatus, roleName?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<UserModelPaginationResponse> {
            return localVarFp.searchUsers(ids, searchText, tenantId, status, roleName, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.unlockUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {UserUpdatingRequest} [userUpdatingRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: string, userUpdatingRequest?: UserUpdatingRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateUser(id, userUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public blockUser(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).blockUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {PasswordChangingRequest} [passwordChangingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public changeUserPassword(id: string, passwordChangingRequest?: PasswordChangingRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).changeUserPassword(id, passwordChangingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UserCreationRequest} [userCreationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(userCreationRequest?: UserCreationRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).createUser(userCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {Array<string>} [ids]
     * @param {string} [searchText]
     * @param {string} [tenantId]
     * @param {UserStatus} [status]
     * @param {string} [roleName]
     * @param {number} [pageIndex]
     * @param {number} [pageSize]
     * @param {boolean} [withTotal]
     * @param {number} [fromItemInclusive]
     * @param {number} [toItemExclusive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public searchUsers(ids?: Array<string>, searchText?: string, tenantId?: string, status?: UserStatus, roleName?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).searchUsers(ids, searchText, tenantId, status, roleName, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public unlockUser(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).unlockUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {UserUpdatingRequest} [userUpdatingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(id: string, userUpdatingRequest?: UserUpdatingRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser(id, userUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



