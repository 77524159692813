import { TrialStatusModel, UserStatus } from '../../api';

export const IDENTITY_ERROR_PREFIX = 'identity_';

export enum ActionTypes {
  SET_ALERT = 'SET_ALERT',
  REMOVE_ALERT = 'REMOVE_ALERT',
}

export enum AlertTypes {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export const USER_STATUS_NAMES = {
  [UserStatus.Invited]: 'Приглашен',
  [UserStatus.Active]: 'Активный',
  [UserStatus.Blocked]: 'Заблокирован',
};

export const TRIAL_STATUS_NAMES = {
  [TrialStatusModel.Requested]: 'Запрошен',
  [TrialStatusModel.Active]: 'Активный',
  [TrialStatusModel.Expired]: 'Истек',
  [TrialStatusModel.Cancelled]: 'Отменен',
};
