import React, { ChangeEvent, useContext, useState } from 'react';
import { Button, Form, Input, Modal, Row, Tooltip } from 'antd';
import { DeleteOutlined, RollbackOutlined } from '@ant-design/icons';

import { StoreContext } from '../../store';
import { RoleModel } from '../../../api';
import { roleApi } from '../../apis';
import { AlertTypes } from '../../constants';

export interface IRoleDeletionProps {
  role: RoleModel;
  onDeleted: () => void;
}

const RoleDeletion: React.FC<IRoleDeletionProps> = ({ role, onDeleted = () => {} }) => {
  const { SetAlert } = useContext(StoreContext).actions;
  const [modalVisible, setModalVisible] = useState(false);
  const [deletionDisabled, setDeletionDisabled] = useState(true);

  const onOpenModalBtnClick = () => {
    setModalVisible(true);
  };

  const onModalCancel = () => {
    setModalVisible(false);
  };

  const onDelete = async () => {
    if (deletionDisabled) {
      return;
    }

    try {
      await roleApi.deleteRole(role.id);
      SetAlert({
        type: AlertTypes.SUCCESS,
        message: 'Роль удалена',
        description: role.name || '',
      });
    } catch (e) {
      SetAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при удалении роли',
        description: e.toString(),
      });
    }

    setModalVisible(false);
    onDeleted();
  };

  const onNameEdit = (e: ChangeEvent<HTMLInputElement>) => {
    setDeletionDisabled(e.target.value !== role.name);
  };

  return (
    <div>
      <Tooltip placement="top" title="Удалить роль">
        <Button danger icon={<DeleteOutlined />} type="ghost" onClick={onOpenModalBtnClick} />
      </Tooltip>
      <Modal
        destroyOnClose
        footer={[
          <Row key="buttons" align="middle" justify="end" style={{ width: '100%' }}>
            <Button icon={<RollbackOutlined />} onClick={onModalCancel}>
              Отмена
            </Button>
            <Button danger disabled={deletionDisabled} icon={<DeleteOutlined />} type="primary" onClick={onDelete}>
              Удалить
            </Button>
          </Row>,
        ]}
        title="Удаление роли"
        visible={modalVisible}
        onCancel={onModalCancel}
      >
        <Form onFinish={onDelete}>
          <Form.Item name="name">
            <Input placeholder="Наименование" onChange={onNameEdit} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default RoleDeletion;
