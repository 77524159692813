import React from 'react';
import { Button, Input, Table, Tooltip } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { UserLoginInfoModel } from '../../../api';

export interface ILoginInfoListEditProps {
  logins: UserLoginInfoModel[];
  onChanged: (items: UserLoginInfoModel[]) => void;
}

const LoginInfoListEdit: React.FC<ILoginInfoListEditProps> = ({ logins, onChanged = () => {} }) => {
  const onLoginProviderChanged = (value: string, index: number) => {
    const items = [...logins];
    items[index] = {
      loginProvider: value,
      providerKey: logins[index].providerKey,
      providerDisplayName: logins[index].providerDisplayName,
    };
    onChanged(items);
  };

  const onProviderKeyChanged = (value: string, index: number) => {
    const items = [...logins];
    items[index] = {
      loginProvider: logins[index].loginProvider,
      providerKey: value,
      providerDisplayName: logins[index].providerDisplayName,
    };
    onChanged(items);
  };

  const onProviderDisplayNameChanged = (value: string, index: number) => {
    const items = [...logins];
    items[index] = {
      loginProvider: logins[index].loginProvider,
      providerKey: logins[index].providerKey,
      providerDisplayName: value,
    };
    onChanged(items);
  };

  const onAddItem = () => {
    const items = [...logins];
    items.push({ loginProvider: '', providerKey: '' });
    onChanged(items);
  };

  const onRemoveItem = (index: number) => {
    const items = [...logins];
    items.splice(index, 1);
    onChanged(items);
  };

  const columns = [
    {
      title: 'ИД внешнего поставщика',
      dataIndex: 'loginProvider',
      key: 'loginProvider',
      render: (value: string, _: unknown, index: number) => (
        <Input
          required
          placeholder="Укажите ИД внешнего поставщика"
          value={value}
          onChange={(e) => onLoginProviderChanged(e.target.value, index)}
        />
      ),
    },
    {
      title: 'ИД пользователя в поставщике',
      dataIndex: 'providerKey',
      key: 'providerKey',
      render: (value: string, _: unknown, index: number) => (
        <Input
          required
          placeholder="Укажите ИД тенанта в поставщике"
          value={value}
          onChange={(e) => onProviderKeyChanged(e.target.value, index)}
        />
      ),
    },
    {
      title: 'Отображаемое имя пользователя в поставщике',
      dataIndex: 'providerDisplayName',
      key: 'providerDisplayName',
      render: (value: string, _: unknown, index: number) => (
        <Input
          placeholder="Укажите отображаемое имя пользователя в поставщике"
          value={value}
          onChange={(e) => onProviderDisplayNameChanged(e.target.value, index)}
        />
      ),
    },
    {
      width: '120px',
      title: () => (
        <Tooltip placement="top" title="Добавить информацию о пользователе из внешнего поставщика">
          <Button icon={<PlusCircleOutlined />} type="ghost" onClick={() => onAddItem()}>
            Добавить
          </Button>
        </Tooltip>
      ),
      render: (_: string, __: unknown, index: number) => (
        <Tooltip placement="top" title="Удалить информацию о пользователе из внешнего поставщика">
          <Button danger icon={<DeleteOutlined />} type="ghost" onClick={() => onRemoveItem(index)}>
            Удалить
          </Button>
        </Tooltip>
      ),
    },
  ];

  return <Table columns={columns} dataSource={logins} pagination={false} />;
};

export default LoginInfoListEdit;
