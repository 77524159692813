import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router';

import './App.less';
import { browserHistory } from './utils/historyUtil';
import BaseLayout from './layouts/BaseLayout';
import TenantList from './pages/tenants/TenantList';
import TenantAdd from './pages/tenants/TenantAdd';
import TenantCard from './pages/tenants/TenantCard';
import TenantEdit from './pages/tenants/TenantEdit';
import RoleList from './pages/roles/RoleList';
import RoleAdd from './pages/roles/RoleAdd';
import RoleCard from './pages/roles/RoleCard';
import RoleEdit from './pages/roles/RoleEdit';
import UserList from './pages/users/UserList';
import UserAdd from './pages/users/UserAdd';
import UserCard from './pages/users/UserCard';
import UserEdit from './pages/users/UserEdit';
import TrialList from './pages/trials/TrialList';

function App(): JSX.Element {
  return (
    <Router history={browserHistory}>
      <Switch>
        <Route
          exact
          path="/tenants"
          render={() => (
            <BaseLayout>
              <TenantList />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/tenants/add"
          render={() => (
            <BaseLayout>
              <TenantAdd />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/tenants/:id"
          render={() => (
            <BaseLayout>
              <TenantCard />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/tenants/:id/edit"
          render={() => (
            <BaseLayout>
              <TenantEdit />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/roles"
          render={() => (
            <BaseLayout>
              <RoleList />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/roles/add"
          render={() => (
            <BaseLayout>
              <RoleAdd />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/roles/:id"
          render={() => (
            <BaseLayout>
              <RoleCard />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/roles/:id/edit"
          render={() => (
            <BaseLayout>
              <RoleEdit />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/users"
          render={() => (
            <BaseLayout>
              <UserList />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/users/add"
          render={() => (
            <BaseLayout>
              <UserAdd />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/users/:id"
          render={() => (
            <BaseLayout>
              <UserCard />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/users/:id/edit"
          render={() => (
            <BaseLayout>
              <UserEdit />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/trials"
          render={() => (
            <BaseLayout>
              <TrialList />
            </BaseLayout>
          )}
        />
        <Route path="*">
          <Redirect to="/tenants" />
        </Route>
      </Switch>
      ;
    </Router>
  );
}

export default App;
